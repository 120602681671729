import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
  transformCompleteUrl, sendPostRequest, sendPutRequest, sendDeleteRequest,
} from '../../helper';
import {
  REST_URL_APPROVALS, REST_URL_VIEW_APPROVAL,
  REST_URL_APPROVAL_PROVIDERS, REST_URL_APPROVAL_PROCESSES, REST_URL_IMAGE_STORAGE,
  REST_URL_RESEND_EVENT, REST_URL_APPROVAL_GROUPS, REST_URL_VIEW_DELETE_APPROVAL_GROUP,
  REST_URL_ADD_EDIT_APPROVAL_GROUP, REST_URL_ENABLE_DISABLE_APPROVAL_GROUP,
  REST_URL_VIEW_DELETE_FLOW_DESIGNER, TYPE_APPROVER_TABLE_MANAGER_LEVEL,
  REST_URL_ADD_EDIT_FLOW_DESIGNER, TYPE_APPROVER_TABLE_USER,
  REST_URL_FLOW_DESIGNERS, TYPE_APPROVER_TABLE_GROUP,
  REST_URL_APPROVAL_CANCEL,
  APPROVAL_STATUS_WAITING_FOR_APPROVAL,
} from './constant';

import LocalizedString from './localization';

export * from '../../helper';

export const getName = (type, level, userName, groupName) => {
  switch (type) {
    case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
      return `${LocalizedString.flowDesignerPage.labelManagerLevel} ${level}`;
    case TYPE_APPROVER_TABLE_USER:
      return userName;
    case TYPE_APPROVER_TABLE_GROUP:
      return groupName;
    default: return '';
  }
};

export const downloadApprovals = async (
  pageNumber, pageSize, orderBy, searchText,
  filterString, token,
) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_APPROVALS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: x.approvalStatus !== APPROVAL_STATUS_WAITING_FOR_APPROVAL,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadApproval = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_APPROVAL.replace(/\{id\}/, id), token);
  return response;
};

export const saveApprovalAttachment = async (filePath) => {
  const url = `${REST_URL_IMAGE_STORAGE}${filePath}`;
  const response = await sendGetFileRequest(url);
  return response;
};

export const downloadApprovalProviders = async (token) => {
  const response = await sendGetRequest(REST_URL_APPROVAL_PROVIDERS, token);
  return response;
};

export const downloadApprovalProcesses = async (token) => {
  const response = await sendGetRequest(REST_URL_APPROVAL_PROCESSES, token);
  return response;
};

export const cancelApproval = async (id, notes, token) => {
  const body = { approvalId: id, notes: notes.notes };
  await sendPostRequest(REST_URL_APPROVAL_CANCEL, body, token);
};

export const resendEvent = async (type, approvalId, token) => {
  const body = {
    type,
    approvalId,
  };
  await sendPostRequest(REST_URL_RESEND_EVENT, body, token);
};

export const transformCustomerGroupDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.fullName || item.name || item.title,
  value: item.id,
  email: item.email,
}));

export const downloadApprovalGroups = async (
  pageNumber, pageSize, orderBy, searchText,
  filterString, token,
) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_APPROVAL_GROUPS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadApprovalGroup = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_APPROVAL_GROUP.replace(/\{id\}/, id), token);
  return response;
};

export const addApprovalGroup = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_APPROVAL_GROUP, body, token);
};

export const editApprovalGroup = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_APPROVAL_GROUP, body, token);
};

export const deleteApprovalGroup = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_APPROVAL_GROUP.replace(/\{id\}/, id), token);
};

export const enableDisableApprovalGroup = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_APPROVAL_GROUP, body, token);
};

export const downloadFlowDesigners = async (
  pageNumber, pageSize, orderBy, searchText,
  filterString, token,
) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_FLOW_DESIGNERS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);

  return transformQueryResult(response);
};

export const downloadFlowDesigner = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_FLOW_DESIGNER.replace(/\{id\}/, id), token);
  return response;
};

export const addFlowDesigner = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_FLOW_DESIGNER, body, token);
};

export const editFlowDesigner = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_FLOW_DESIGNER, body, token);
};

export const deleteFlowDesigner = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_FLOW_DESIGNER.replace(/\{id\}/, id), token);
};
