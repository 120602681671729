/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  IconButton, makeStyles, Typography, Box, Avatar,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import {
  File, FileExcel, FilePdfBox, FilePowerpoint, FileWord, ZipBox,
} from 'mdi-material-ui';
import {
  COLOR_BODY_TEXT,
  COLOR_BODY_TEXT_LIGHT,
  COLOR_BODY_TEXT_LIGHTER, COLOR_DANGEROUS,
  COLOR_ICON,
  COLOR_POLYGON,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_TEXT_LIGHTEST,
  FILE_EXTENSION_DOCUMENT,
  FILE_EXTENSION_PDF,
  FILE_EXTENSION_SLIDESHOW,
  FILE_EXTENSION_SPREADSHEET,
  FILE_EXTENSION_ZIP,
} from '../../constant';
import LocalizedString from '../../localization';
import { getImageValue } from '../../helper';
import { StyleShape } from '../../type';

const uploadIcon = require('../../asset/upload-icon.png');

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 80,
    height: 80,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  container: {
    display: 'inline-block',
    padding: '5px',
    borderRadius: '5px',
    width: '100%',
    border: `dotted 2px ${COLOR_SECONDARY}`,
    backgroundColor: COLOR_TERTIARY,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  primaryText: {
    color: COLOR_PRIMARY,
    textDecoration: 'underline',
    margin: '0px 4px 0px 8px',
  },
  disabledText: {
    color: COLOR_BODY_TEXT_LIGHT,
    fontSize: '14px',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
  },
  label: {
    color: COLOR_ICON,
    fontSize: '14px',
    marginLeft: '10px',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  border: {
    margin: 8,
    border: `dotted 2px ${COLOR_BODY_TEXT_LIGHTER}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    color: COLOR_BODY_TEXT_LIGHTER,
  },
  containerAvatar: {
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainerAvatar: {
    paddingLeft: 15,
  },
  helperText: {
    color: COLOR_TEXT_LIGHTEST,
    fontSize: '12px',
    marginTop: 8,
    marginLeft: 10,
  },
  errorText: {
    color: COLOR_DANGEROUS,
    fontSize: '12px',
    marginTop: 8,
    marginLeft: 10,
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  fileNameText: {
    color: COLOR_BODY_TEXT_LIGHTER,
    fontSize: '14px',
    marginLeft: '10px',
    fontStyle: 'italic',
  },
}));

const renderAvatarImage = (classes, files, value) => (
  <div className={classes.innerContainerAvatar}>
    <Avatar src={files[0]?.preview || getImageValue(value)} className={classes.avatar} />
  </div>
);

const renderAvatar = (result, value, classes, dialogType,
  visibility, newImage, loading, disabled, files) => {
  if (!result && !value && !loading) {
    if ((dialogType === 'add' && visibility) || (!disabled)) {
      return null;
    }

    return (
      <Box className={classes.border}>
        <Typography className={classes.messageText}>
          {LocalizedString.common.errMsgNoDataYet}
        </Typography>
      </Box>
    );
  }

  if (files && loading) {
    return (
      renderAvatarImage(classes, files, value)
    );
  }

  if (newImage && loading === false) {
    return null;
  }

  if (result || value) {
    return (
      renderAvatarImage(classes, files, value)
    );
  }

  return (
    <div>
      <Avatar className={classes.avatar} />
    </div>
  );
};

const renderDeleteButton = (handleRemoveImage, index, classes) => (
  <div className={classes.deleteButton}>
    <IconButton
      aria-label="delete"
      size="small"
      onClick={() => handleRemoveImage(index)}
    >
      <Clear fontSize="inherit" />
    </IconButton>
  </div>
);

const getIcon = (acceptedFileExtension) => {
  switch (acceptedFileExtension) {
    case FILE_EXTENSION_PDF: return { icon: FilePdfBox, color: COLOR_POLYGON };
    case FILE_EXTENSION_SPREADSHEET: return { icon: FileExcel, color: COLOR_SECONDARY };
    case FILE_EXTENSION_SLIDESHOW: return { icon: FilePowerpoint, color: COLOR_DANGEROUS };
    case FILE_EXTENSION_DOCUMENT: return { icon: FileWord, color: COLOR_PRIMARY };
    case FILE_EXTENSION_ZIP: return { icon: ZipBox, color: COLOR_TERTIARY };
    default: return { icon: File, color: COLOR_BODY_TEXT };
  }
};

const renderPreviews = (files, classes, handleRemoveImage,
  disabled, defaultValue, result, dialogType, visibility,
  newImage, loading, deleteButton, acceptedFileExtension) => {
  if (disabled) {
    return renderAvatar(result, defaultValue, classes, dialogType,
      visibility, newImage, loading, disabled, files);
  }
  return (
    <aside className={classes.thumbsContainer}>
      {files.map((file, index) => {
        const { icon: Icon, color } = getIcon(acceptedFileExtension || file.type);

        return (
          <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
              {acceptedFileExtension ? (
                <Icon src={getIcon().icon} variant="square" style={{ color, marginRight: 12 }} />
              ) : (
                <img
                  src={file.preview}
                  className={classes.img}
                  alt="preview"
                />
              )}
            </div>
            {deleteButton && renderDeleteButton(handleRemoveImage, index, classes)}
          </div>
        );
      })}
      {renderAvatar(result, defaultValue, classes, dialogType,
        visibility, newImage, loading, disabled, files)}
    </aside>
  );
};

const DropzonePicker = ({
  error, hidden, maxFiles, required,
  onFileChange, onImageSelected,
  label, imageFileExtension, disabled,
  defaultValue, result, dialogType, visibility,
  loading, deleteButton, helperText, acceptedFileExtension,
  onFileSelected, containerStyle, maxSize, errorMessage,
}) => {
  const [files, setFiles] = useState([]);
  const [newImage, setNewImage] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileChange = (acceptedFiles) => {
    if (onImageSelected) {
      onFileChange(acceptedFiles, onImageSelected);
    } else {
      onFileChange(acceptedFiles, onFileSelected);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...files];
    const removedFile = updatedFiles.splice(index, 1)[0];
    URL.revokeObjectURL(removedFile.preview);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onError: error,
    accept: imageFileExtension || acceptedFileExtension,
    maxFiles,
    maxSize,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));
      setFiles(newFiles);
      handleFileChange(newFiles);
      setNewImage(files);
    },
  });
  const classes = useStyles();

  useEffect(() => () => files.forEach((file) => {
    URL.revokeObjectURL(file.preview);
    setFileName(file.name);
  }, [files]));

  const labelText = label || LocalizedString.common.labelFileUpload;
  const labelProps = required ? `${labelText} *` : labelText;
  if (!hidden && !disabled) {
    return (
      <div className={containerStyle}>
        <Typography className={classes.label}>
          {labelProps}
        </Typography>
        <div className={classes.previewContainer}>
          <section className={classes.container}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={classes.rowContainer}>
                <img alt="upload" src={uploadIcon} width={16} height={16} />
                <p className={classes.primaryText}>{LocalizedString.common.labelBrowseFile}</p>
                <p className={classes.disabledText}>{LocalizedString.common.labelDropzonePicker}</p>
              </div>
            </div>
          </section>
          {renderPreviews(files, classes, handleRemoveImage,
            disabled, defaultValue, result, dialogType,
            visibility, newImage, loading, deleteButton,
            acceptedFileExtension)}
        </div>
        <Typography className={classes.helperText}>
          {helperText}
        </Typography>
        <Typography className={classes.errorText}>
          {errorMessage}
        </Typography>
        <div className={classes.fileNameContainer}>
          <Typography className={classes.fileNameText}>{fileName}</Typography>
        </div>
      </div>
    );
  }
  if (disabled) {
    return (
      <div className={classes.containerAvatar}>
        <Typography className={classes.label}>{label}</Typography>
        {renderPreviews(files, classes, handleRemoveImage,
          disabled, defaultValue, result, dialogType,
          visibility, newImage, loading, deleteButton,
          acceptedFileExtension)}
      </div>
    );
  }
  return null;
};

DropzonePicker.propTypes = {
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  deleteButton: PropTypes.bool,
  required: PropTypes.bool,
  files: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  dialogType: PropTypes.string.isRequired,
  visibility: PropTypes.bool.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  maxFiles: PropTypes.number,
  result: PropTypes.string,
  imageFileExtension: PropTypes.string,
  acceptedFileExtension: PropTypes.string,
  errorMessage: PropTypes.string,
  containerStyle: StyleShape,
  maxSize: PropTypes.number,
};

DropzonePicker.defaultProps = {
  loading: false,
  error: false,
  hidden: false,
  deleteButton: false,
  maxFiles: 1,
  disabled: false,
  required: false,
  defaultValue: '',
  label: null,
  helperText: null,
  result: '',
  imageFileExtension: {
    'image/jpeg': [],
    'image/png': [],
  },
  acceptedFileExtension: {
    'text/csv': [],
  },
  containerStyle: {},
  maxSize: undefined,
  errorMessage: '',
};

export default DropzonePicker;
