import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CERT_REQUEST_DIALOG_MODE_SELECT_FILE, INITIAL_ORDER_BY_ASSET_DOCUMENTS, PAGE_MODE_TABLE,
} from '../../../constant';
import { sortIntAsc, transformInitialValues } from '../../../helper';
import {
  checkHasCertificationRequetApprovalPermissionAsync,
  clearAssetDocuments, clearCertificationRequestApprovals, downloadAssetDocumentsAsync,
  downloadCertificationRequestApprovalsAsync, setAlertErrorMessage, setAlertInputMessage,
  setAssetDocumentAdvancedFilterDialogSelectedFilterString, setAssetDocumentSearchText,
  setAssetDocumentSelectedOrderBy, setAssetDocumentSelectedPageSize,
  setCertificationRequestActiveTab, setCertificationRequestDialogVisibility,
} from '../../../redux/action';
import CertificationRequestDetailPage from './detail.presentation';

const getInitialValues = (state) => {
  const {
    certificationRequests, uiFunctionalPage, uiCertificationRequest,
  } = state;
  const { data } = certificationRequests;
  const { downloadingDeleting, tappedId } = uiCertificationRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found, {
    pic: found?.pic?.fullName || '',
    assetLocation: found?.assetLocation?.name || '',
    certificationCategory: found?.certificationCategory || '',
    certificationType: found?.certificationType?.name || '',
  }) : {};
  return result;
};

const getApproval = (state) => {
  const { certificationRequestApprovals, uiCertificationRequest: { downloadingApprovals } } = state;

  if (!downloadingApprovals) {
    const steps = certificationRequestApprovals?.steps;
    const result = steps?.length
      ? steps.sort((a, b) => sortIntAsc(a.order, b.order)) : [];
    return result;
  }
  return [];
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  approvals: getApproval(state),
  approving: state.uiCertificationRequest.approving,
  checkingApprovalPermission: state.uiCertificationRequest.checkingApprovalPermission,
  downloadingApprovals: state.uiCertificationRequest.downloadingApprovals,
  downloadingDeleting: state.uiCertificationRequest.downloadingDeleting,
  hasApprovalPermission: state.uiCertificationRequest.hasApprovalPermission,
  rejecting: state.uiCertificationRequest.rejecting,
  activeTab: state.uiCertificationRequest.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (isApprovalTabActive) => {
    if (isApprovalTabActive) {
      dispatch(clearCertificationRequestApprovals());
      dispatch(downloadCertificationRequestApprovalsAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
      dispatch(checkHasCertificationRequetApprovalPermissionAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onApproveRejectPressed: (title, subtitle, label, placeholder) => {
    dispatch(setAlertInputMessage(title, placeholder, label, subtitle));
  },
  onPdfPressed: (tappedAssetId) => {
    dispatch(setCertificationRequestDialogVisibility(true, CERT_REQUEST_DIALOG_MODE_SELECT_FILE));
    dispatch(setAssetDocumentAdvancedFilterDialogSelectedFilterString(`asset.id=${tappedAssetId}`));
    dispatch(setAssetDocumentSearchText(''));
    dispatch(clearAssetDocuments());
    dispatch(setAssetDocumentSelectedPageSize(20));
    dispatch(setAssetDocumentSelectedOrderBy(INITIAL_ORDER_BY_ASSET_DOCUMENTS));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onTabPressed: (tab) => {
    dispatch(setCertificationRequestActiveTab(tab));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationRequestDetailPage);
