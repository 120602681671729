import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_APPROVALS = '/approval/api/v1/Approval?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|approvalNumber|title|description|referenceId';
export const REST_URL_VIEW_APPROVAL = '/approval/api/v1/Approval/{id}';
export const REST_URL_APPROVAL_PROVIDERS = '/approval/api/v1/ApprovalProvider';
export const REST_URL_APPROVAL_PROCESSES = '/approval/api/v1/ApprovalProcess';
export const REST_URL_APPROVAL_CANCEL = '/approval/api/v1/ApprovalCancel';
export const REST_URL_RESEND_EVENT = '/approval/api/v1/ApprovalEvent';
export const REST_URL_APPROVAL_GROUPS = '/approval/api/v1/ApprovalGroup?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=';
export const REST_URL_VIEW_DELETE_APPROVAL_GROUP = '/approval/api/v1/ApprovalGroup/{id}';
export const REST_URL_ADD_EDIT_APPROVAL_GROUP = '/approval/api/v1/ApprovalGroup';
export const REST_URL_ENABLE_DISABLE_APPROVAL_GROUP = '/approval/api/v1/ApprovalGroup/Status';
export const REST_URL_FLOW_DESIGNERS = '/approval/api/v1/WorkflowDesigner?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=';
export const REST_URL_VIEW_DELETE_FLOW_DESIGNER = '/approval/api/v1/WorkflowDesigner/{id}';
export const REST_URL_ADD_EDIT_FLOW_DESIGNER = '/approval/api/v1/WorkflowDesigner';

export const ROUTE_NAME_APPROVAL = '/approval';
export const ROUTE_NAME_APPROVAL_GROUP = '/approval-group';
export const ROUTE_NAME_FLOW_DESIGNER = '/flow-designer';

export const RXFORM_APPROVAL = 'approvalPage';
export const RXFORM_APPROVAL_GROUP = 'approvalGroupPage';
export const RXFORM_FLOW_DESIGNER = 'flowDesignerPage';
export const RXFORM_APPROVER_TABLE = 'approvalTable';

export const RXFIELD_APPROVAL_PROCESS_NAME = 'processName';
export const RXFIELD_APPROVAL_APPROVAL_NUMBER = 'approvalNumber';
export const RXFIELD_APPROVAL_TITLE = 'title';
export const RXFIELD_APPROVAL_DESCRIPTION = 'description';
export const RXFIELD_APPROVAL_TAG = 'tags';
export const RXFIELD_APPROVAL_NOTES = 'notes';
export const RXFIELD_APPROVAL_APPROVAL_STATUS = 'approvalStatus';
export const RXFIELD_APPROVAL_STATUS_CHANGED_AT = 'statusChangedAt';
export const RXFIELD_APPROVAL_REQUESTER_ID = 'requesterId';
export const RXFIELD_APPROVAL_REQUESTER_NAME = 'requesterName';
export const RXFIELD_APPROVAL_REQUESTER_NOTES = 'requesterNotes';
export const RXFIELD_APPROVAL_REFERENCE_ID = 'referenceId';
export const RXFIELD_APPROVAL_PROVIDER_NAME = 'providerName';
export const RXFIELD_APPROVAL_ALLOW_TO_ADD_ATTACHMENT = 'allowToAddAttachments';
export const RXFIELD_APPROVAL_DISABLE_EMAIL_NOTIFICATION = 'disableEmailNotification';
export const RXFIELD_APPROVAL_DISABLE_APP_NOTIFICATION = 'disableAppNotification';
export const RXFIELD_APPROVAL_STEPS = 'steps';
export const RXFIELD_APPROVAL_ATTACHMENTS = 'attachments';
export const RXFIELD_APPROVAL_DATA = 'data';
export const RXFIELD_APPROVAL_APPROVERS = 'approvers';
export const RXFIELD_APPROVAL_COMMENTS = 'comments';
export const RXFIELD_APPROVAL_RESEND_EVENT = 'resendEvent';
export const RXFIELD_APPROVAL_GROUP_NAME = 'name';
export const RXFIELD_APPROVAL_GROUP_DESCRIPTION = 'description';
export const RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS = 'members';
export const RXFIELD_FLOW_DESIGNER_PROCESS_NAME = 'processName';
export const RXFIELD_FLOW_DESIGNER_DESCRIPTION = 'description';
export const RXFIELD_FLOW_DESIGNER_WORKFLOW = 'workflow';
export const RXFIELD_FLOW_DESIGNER_STEP_NAME = 'stepName';
export const RXFIELD_FLOW_DESIGNER_TYPE = 'type';
export const RXFIELD_FLOW_DESIGNER_MAX_LEVEL = 'maxLevel';
export const RXFIELD_FLOW_DESIGNER_APPROVER_MODE = 'approverMode';
export const RXFIELD_FLOW_DESIGNER_APPROVERS = 'approvers';
export const RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE = 'type';
export const RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER = 'user';
export const RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME = 'groupName';
export const RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE = 'groupMode';
export const RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL = 'level';

export const RXSTATE_APPROVALS = 'approvals';
export const RXSTATE_APPROVAL_PAGE = 'uiApproval';
export const RXSTATE_APPROVAL_GROUPS = 'approvalGroups';
export const RXSTATE_APPROVAL_GROUP_PAGE = 'uiApprovalGroup';
export const RXSTATE_FLOW_DESIGNERS = 'flowDesigners';
export const RXSTATE_FLOW_DESIGNER_PAGE = 'uiFlowDesigner';

export const INITIAL_ORDER_BY_APPROVALS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_APPROVAL_GROUPS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_FLOW_DESIGNERS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_APPROVAL = 'MENUID_APPROVAL';
export const MENUID_APPROVAL_APPROVAL = 'MENUID_APPROVAL_APPROVAL';
export const MENUID_APPROVAL_GROUP = 'MENUID_APPROVAL_GROUP';
export const MENUID_FLOW_DESIGNER = 'MENUID_FLOW_DESIGNER';

export const APPROVAL_STATUS = ['WaitingForApproval', 'Cancelled', 'Approved', 'Rejected'];
export const APPROVAL_GROUP_STATUS = ['Enabled', 'Disabled'];

export const RESEND_EVENT_AT_VALUES = ['StatusChanged', 'ApprovalCompleted'];

export const TYPE_DIRECT_MANAGER = 'DirectManager';
export const TYPE_REGULAR = 'Regular';

export const TYPE_DROPDOWN = [
  TYPE_DIRECT_MANAGER,
  TYPE_REGULAR,
];

export const APPROVER_MODE_ALL = 'All';
export const APPROVER_MODE_ONE = 'One';

export const APPROVER_MODE_DROPDOWN = [
  APPROVER_MODE_ALL,
  APPROVER_MODE_ONE,
];

export const TYPE_APPROVER_TABLE_USER = 'User';
export const TYPE_APPROVER_TABLE_MANAGER_LEVEL = 'ManagerLevel';
export const TYPE_APPROVER_TABLE_GROUP = 'Group';

export const TYPE_APPROVER_TABLE_DROPDOWN = [
  TYPE_APPROVER_TABLE_USER,
  TYPE_APPROVER_TABLE_MANAGER_LEVEL,
  TYPE_APPROVER_TABLE_GROUP,
];

export const APPROVER_TABLE_GROUP_MODE_SELECT_ALL = 'SelectAll';
export const APPROVER_TABLE_GROUP_MODE_SELECT_ONE = 'SelectOne';

export const APPROVER_TABLE_GROUP_MODE = [
  APPROVER_TABLE_GROUP_MODE_SELECT_ALL,
  APPROVER_TABLE_GROUP_MODE_SELECT_ONE,
];

export const DragDropItemTypes = {
  FIELD: 'field',
};

export const APPROVAL_STATUS_WAITING_FOR_APPROVAL = 'WaitingForApproval';
