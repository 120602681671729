import {
  clearConfigItems,
  creatingConfigItemPermission,
  setAlertErrorMessage, setAlertInfoMessage,
} from '../simple-action';
import { createPermissionConfigItem, downloadConfigItem } from '../../../helper';
import downloadConfigItemsAsync from './downloadConfigItemsAsync';
import { CREATE_PERMISSION_DESCRIPTION } from '../../../constant';
import LocalizedString from '../../../localization';

export default () => async (dispatch, getState) => {
  try {
    dispatch(creatingConfigItemPermission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiConfigItem;

    const result = await downloadConfigItem(tappedId, token);

    const configGroupName = result.configGroup.name.toUpperCase();
    const configItemName = result.name.toUpperCase();

    await createPermissionConfigItem(
      `CONFIGPERMISSION_${configGroupName}_${configItemName}`,
      CREATE_PERMISSION_DESCRIPTION,
      token,
    );

    dispatch(clearConfigItems());

    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(creatingConfigItemPermission(false));
    dispatch(setAlertInfoMessage(LocalizedString.configItemScreen.alertCreatePermissionSuccess));
  }
};
