import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  PAGE_MODE_TABLE,
  REVERSED_ISO_DATE_FORMAT,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE,
  RXFORM_ASSET_CERTIFICATE,
} from '../../../constant';
import { toMoment, transformInitialValues } from '../../../helper';
import {
  addEditAssetCertificateAsync, setAlertErrorMessage,
  setCertificationScheduleSettingDialogVisibility, setCertificationScheduleSettingSelectedFile,
  setCertificationScheduleSettingSelectedResultType,
} from '../../../redux/action';
import AddEditCertificationScheduleSettingDialog from './add-edit-dialog.presentation';

const getInitialValues = (state) => {
  const { assetCertificates, uiFunctionalPage, uiAssetCertificate } = state;
  const { data } = assetCertificates;
  const { downloadingDeleting, tappedId } = uiAssetCertificate;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  addingEditing: state.uiAssetCertificate.addingEditing,
  dialogVisibility: state.uiCertificationScheduleSetting.dialogVisibility,
  downloadingDeleting: state.uiAssetCertificate.downloadingDeleting,
  certificationType: state.assets.data[state.uiAsset.tappedId]?.certificationType?.name,
  dialogMode: state.uiCertificationScheduleSetting.dialogMode,
});

const mapDispatchToProps = (dispatch) => ({
  onCancelPressed: () => {
    dispatch(setCertificationScheduleSettingSelectedFile(''));
    dispatch(setCertificationScheduleSettingSelectedResultType(''));
    dispatch(reset(RXFORM_ASSET_CERTIFICATE));
    dispatch(setCertificationScheduleSettingDialogVisibility(false, ''));
  },
  onFileSelected: (base64, fileName) => {
    dispatch(setCertificationScheduleSettingSelectedFile({ fileContent: base64, fileName }));
    dispatch(change(RXFORM_ASSET_CERTIFICATE, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE, base64));
  },
  onResultTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setCertificationScheduleSettingSelectedResultType(option));
      dispatch(change(RXFORM_ASSET_CERTIFICATE,
        RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE, option));
    } else {
      dispatch(setCertificationScheduleSettingSelectedResultType(''));
      dispatch(change(RXFORM_ASSET_CERTIFICATE, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE, ''));
    }
  },
  onSavePressed: async ({ remark, issuedDate, expiredDate }) => {
    try {
      await dispatch(addEditAssetCertificateAsync(remark,
        toMoment(issuedDate).format(REVERSED_ISO_DATE_FORMAT),
        toMoment(expiredDate).format(REVERSED_ISO_DATE_FORMAT)));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  AddEditCertificationScheduleSettingDialog,
);
