import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    eCertificationMenu: {
      title: 'E-Certification',
    },
    assetPage: {
      title: 'Asset List',
      titleDetail: 'Asset Detail Form',
      titleAddChildAssets: 'Add Child Assets',

      labelNo: 'No',
      labelId: 'ID',
      labelAssetDescription: 'Asset Description',
      labelPartCode: 'Part Code',
      labelSerialNumber: 'Serial Number',
      labelAssetNumber: 'Asset Number',
      labelAssetClass: 'Asset Class',
      labelLocation: 'Location',
      labelCertType: 'Cert Type',
      labelCertCategory: 'Cert Category',
      labelCertDate: 'Cert Date',
      labelCertExpired: 'Cert Expired',
      labelDescription: 'Description',
      labelOwner: 'Owner',
      labelExpired: 'Expired',
      labelDate: 'Date',
      labelResultType: 'Result Type',
      labelFile: 'File',
      labelRemark: 'Remark',
      labelDocument: 'Document',
      labelFileName: 'File Name',
      labelExpiredReminder: 'Expired Reminder',
      labelDocumentName: 'Latest Document Name',

      buttonCaptionCertifications: 'Certifications',
      buttonCaptionDocument: 'Document',
      buttonCaptionSetting: 'Setting',
      buttonCaptionAssetChild: 'Asset Child',
      buttonCaptionAddAsset: 'Add Asset',

      msgRemoveConfirmation: 'Are you sure want to remove this item?',
      msgFileValidation: 'File format: .pdf, .xls, .xlsx (Max) File Size: 5MB',
    },
    certificationTypePage: {
      title: 'Certification Type',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreateNewCertificationType: 'Create New Certification Type',
      buttonCaptionEditCertificationType: 'Edit Certification Type',
      buttonCaptionDeleteCertificationType: 'Delete Certification Type',
    },
    certificationRequestPage: {
      title: 'Certification Request List',
      titleCreate: 'Create Certification Request Form',
      titleDetail: 'Certification Request Form View',
      titleSelectFile: 'Select File Document',
      titleSelectAsset: 'Select Asset',
      menuTitle: 'Certification Request',

      labelNo: 'No',
      labelId: 'ID',
      labelPic: 'PIC',
      labelOwner: 'Owner',
      labelCategory: 'Category',
      labelType: 'Type',
      labelStatus: 'Status',
      labelAssetDescription: 'Asset Description',
      labelPartCode: 'Part Code',
      labelSerialNumber: 'Serial Number',
      labelAssetNumber: 'Asset Number',
      labelAssetClass: 'Asset Class',
      labelLocation: 'Location',
      labelExpired: 'Expired',
      labelFileName: 'File Name',
      labelTitle: 'Title',
      labelStart: 'Start',
      labelEnd: 'End',
      labelRemarks: 'Remarks',
      labelAssetOwner: 'Asset Owner',
      labelCertificationType: 'Certification Type',
      labelApproved: 'Approved',
      labelOnProgress: 'On Progress',
      labelRejected: 'Rejected',
      labelCertificationRequest: 'Certification Request',
      labelName: 'Name',

      placeholderSelect: 'Select',
      placeholderRemarks: 'Fill',

      buttonCaptionCreateNewCertificationRequest: 'Create Certificate Request',
      buttonCaptionAsset: 'Asset',
      buttonCaptionApproval: 'Approval',
      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',
      buttonCaptionAddAsset: 'Add Asset',
      buttonCaptionDownloadDocument: 'Download Document',

      msgApproval1Confirmation: 'Are you sure you want to approve this request?',
      msgApproval2Confirmation: 'Are you certain you want to approve this request? Please confirm your decision and provide a reason for the approval.',
      msgRejection1Confirmation: 'Are you sure you want to reject this request?',
      msgRejection2Confirmation: 'Are you certain you want to reject this request? This action cannot be undone. Please confirm your decision and provide a reason for the rejection.',
    },
    certificationScheduleSettingPage: {
      title: 'Certification Schedule Setting',
      titleDetail: 'Certification On Progress Form',
      titleSetSchedule: 'Set Schedule',
      titleAddCertificate: 'Add New Certificate -',
      titleEditCertificate: 'Edit Certificate',

      labelNo: 'No',
      labelType: 'Type',
      labelDescription: 'Description',
      labelAssetNumber: 'Asset Number',
      labelOwner: 'Owner',
      labelPartCode: 'Part Code',
      labelSerialNumber: 'Serial Number',
      labelSchedule: 'Schedule',
      labelCategory: 'Category',
      labelLocation: 'Location',
      labelDate: 'Date',
      labelResultType: 'Result Type',
      labelIssueDate: 'Issue Date',
      labelExpiredDate: 'Expired Date',
      labelRemark: 'Remark',
      labelExpired: 'Expired',
      labelLastProgress: 'Last Progress',
      labelIssuer: 'Issuer',

      placeholderDate: 'YYYY-MM-DD',
      placeholderDropdown: 'Select',
      placeholderText: 'Fill',

      buttonCaptionSetSchedule: 'Set Schedule',
      buttonCaptionAddCertificate: 'Add Certificate',

      msgFileValidation: 'File format: .pdf, .xlsx, .csv (Max) File Size: 5MB',
    },
    assetScreeningPage: {
      title: 'Asset Screening',
      titleNeedCertification: 'Need Certification Confirmation',
      titleNoNeedCertification: 'No Need Certification Confirmation',

      labelStatus: 'Status',
      labelNeedCertification: 'Need Certification',
      labelNoNeedCertification: 'No Need Certification',
      labelType: 'Type',
      labelTotalAssets: 'Total Assets:',
      labelTheseAssets: 'These assets no need certification',

      buttonCaptionNeedCertification: 'Need Certification',
      buttonCaptionNoNeedCertification: 'No Need Certification',
    },
    myApprovalPage: {
      menuTitle: 'My Approval',

      labelNo: 'No.',
      labelTitle: 'Title',
      labelApprovalStatus: 'Approval Status',
      labelStartedAt: 'Started At',
      labelChangedAt: 'Changed At',
    },
  },
  id: {
    eCertificationMenu: {
      title: 'E-Sertifikasi',
    },
    assetPage: {
      title: 'Daftar Aset',
      titleDetail: 'Form Aset Detail',
      titleAddChildAssets: 'Tambah Anak Aset',

      labelNo: 'No',
      labelId: 'ID',
      labelAssetDescription: 'Deskripsi Aset',
      labelPartCode: 'Kode Bagian',
      labelSerialNumber: 'Nomor Seri',
      labelAssetNumber: 'Nomor Aset',
      labelAssetClass: 'Kelas Aset',
      labelLocation: 'Lokasi',
      labelCertType: 'Tipe Sert',
      labelCertCategory: 'Kategori Sert',
      labelCertDate: 'Tanggal Sert',
      labelCertExpired: 'Kadaluarsa Sert',
      labelDescription: 'Deskripsi',
      labelOwner: 'Pemilik',
      labelExpired: 'Kadaluarsa',
      labelDate: 'Tanggal',
      labelResultType: 'Tipe Hasil',
      labelFile: 'Berkas',
      labelRemark: 'Komentar',
      labelDocument: 'Dokumen',
      labelFileName: 'Nama Berkas',
      labelExpiredReminder: 'Pengingat Kadaluarsa',
      labelDocumentName: 'Nama Dokumen Terakhir',

      buttonCaptionCertifications: 'Sertifikasi',
      buttonCaptionDocument: 'Dokumen',
      buttonCaptionSetting: 'Pengaturan',
      buttonCaptionAssetChild: 'Anak Aset',
      buttonCaptionAddAsset: 'Tambah Aset',

      msgRemoveConfirmation: 'Apa Anda yakin ingin menghapus pilihan ini?',
      msgFileValidation: 'Format file: .pdf, .xls, .xlsx  Ukuran File (Maks): 5MB',
    },
    certificationTypePage: {
      title: 'Tipe Sertifikasi',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewCertificationType: 'Buat Tipe Sertifikasi Baru',
      buttonCaptionEditCertificationType: 'Ubah Tipe Sertifikasi',
      buttonCaptionDeleteCertificationType: 'Hapus Tipe Sertifikasi',
    },
    certificationRequestPage: {
      title: 'Daftar Permintaan Sertifikasi',
      titleCreate: 'Buat Form Permintaan Sertifikasi',
      titleDetail: 'Form Permintaan Sertifikasi Detail',
      titleSelectFile: 'Pilih Berkas Dokumen',
      titleSelectAsset: 'Pilih Aset',
      menuTitle: 'Permintaan Sertifikasi',

      labelNo: 'No',
      labelId: 'ID',
      labelPic: 'PIC',
      labelOwner: 'Pemilik',
      labelCategory: 'Kategori',
      labelType: 'Tipe',
      labelStatus: 'Status',
      labelAssetDescription: 'Deskripsi Aset',
      labelPartCode: 'Kode Bagian',
      labelSerialNumber: 'Nomor Seri',
      labelAssetNumber: 'Nomor Aset',
      labelAssetClass: 'Kelas Aset',
      labelLocation: 'Lokasi',
      labelExpired: 'Kadaluarsa',
      labelFileName: 'Nama Berkas',
      labelTitle: 'Judul',
      labelStart: 'Mulai',
      labelEnd: 'Berakhir',
      labelRemarks: 'Komentar',
      labelAssetOwner: 'Pemilik Aet',
      labelCertificationType: 'Tipe Sertifikasi',
      labelApproved: 'Disetujui',
      labelOnProgress: 'Diproses',
      labelRejected: 'Ditolak',
      labelCertificationRequest: 'Permintaan Sertifikasi',
      labelName: 'Nama',

      placeholderSelect: 'Pilih',
      placeholderRemarks: 'Isi',

      buttonCaptionCreateNewCertificationRequest: 'Buat Permintaan Sertifikat',
      buttonCaptionAsset: 'Aset',
      buttonCaptionApproval: 'Persetujuan',
      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',
      buttonCaptionAddAsset: 'Tambah Aset',
      buttonCaptionDownloadDocument: 'Unduh Dokumen',

      msgApproval1Confirmation: 'Apa Anda yakin ingin menyetujui permintaan ini?',
      msgApproval2Confirmation: 'Apa Anda yakin ingin menyetujui permintaan ini? Silakan konfirmasi pilihan Anda dengan memberikan alasan persetujuan.',
      msgRejection1Confirmation: 'Apa Anda yakin ingin menolak permintaan ini?',
      msgRejection2Confirmation: 'Apa Anda yakin ingin menolak permintaan ini? Tindakan ini tidak dapat dibatalkan. Silakan konfirmasi pilihan Anda dengan memberikan alasan penolakkan.',
    },
    certificationScheduleSettingPage: {
      title: 'Pengaturan Penjadwalan Sertifikasi',
      titleDetail: 'Form Sertifikasi Dalam Proses',
      titleSetSchedule: 'Atur Jadwal',
      titleAddCertificate: 'Tambah Sertifikat Baru -',
      titleEditCertificate: 'Ubah Sertifikat',

      labelNo: 'No',
      labelType: 'Tipe',
      labelDescription: 'Deskripsi',
      labelAssetNumber: 'Nomor Aset',
      labelOwner: 'Pemilik',
      labelPartCode: 'Kode Bagian',
      labelSerialNumber: 'Nomor Seri',
      labelSchedule: 'Jadwal',
      labelCategory: 'Kategori',
      labelLocation: 'Lokasi',
      labelDate: 'Tanggal',
      labelResultType: 'Tipe Hasil',
      labelIssueDate: 'Tanggal Penerbitan',
      labelExpiredDate: 'Tanggal Kadaluarsa',
      labelRemark: 'Komentar',
      labelExpired: 'Kadaluarsa',
      labelLastProgress: 'Progres Terakhir',
      labelIssuer: 'Penerbit',

      placeholderDate: 'YYYY-MM-DD',
      placeholderDropdown: 'Pilih',
      placeholderText: 'Isi',

      buttonCaptionSetSchedule: 'Atur Jadwal',
      buttonCaptionAddCertificate: 'Tambah Sertifikat',

      msgFileValidation: 'Format file: .pdf, .xlsx, .csv Ukuran File (Maks): 5MB',
    },
    assetScreeningPage: {
      title: 'Penyaringan Aset',
      titleNeedCertification: 'Konfirmasi Perlu Sertifikasi',
      titleNoNeedCertification: 'Konfirmasi Tidak Perlu Sertifikasi',

      labelStatus: 'Status',
      labelNeedCertification: 'Perlu Sertifikasi',
      labelNoNeedCertification: 'Tidak Perlu Sertifikasi',
      labelType: 'Tipe',
      labelTotalAssets: 'Total Aset:',
      labelTheseAssets: 'Aset berikut tidak perlu sertifikasi',

      buttonCaptionNeedCertification: 'Perlu Sertifikasi',
      buttonCaptionNoNeedCertification: 'Tidak Perlu Sertifikasi',
    },
    myApprovalPage: {
      menuTitle: 'Persetujuan Saya',

      labelNo: 'No.',
      labelTitle: 'Judul',
      labelApprovalStatus: 'Status Persetujuan',
      labelStartedAt: 'Dimulai Pada',
      labelChangedAt: 'Diubah Pada',
    },
  },
});

export default LocalizedString;
