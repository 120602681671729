import {
  setAlertErrorMessage, cancelingApproval, addApproval,
  setApprovalTappedId, setFunctionalPageMode, clearApprovals,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import { cancelApproval, downloadApproval } from '../../../helper';
import downloadApprovalsAsync from './downloadApprovalsAsync';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(cancelingApproval(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiFunctionalPage;
    const { input } = getState().alert;

    if (input) {
      await cancelApproval(tappedId, notes, token);
      dispatch(setApprovalTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearApprovals());
      dispatch(downloadApprovalsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const myJob = await downloadApproval(tappedId, token);
      dispatch(addApproval(myJob));
    }
  } finally {
    dispatch(cancelingApproval(false));
  }
};
