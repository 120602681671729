import {
  DOWNLOADING_APPROVALS, DOWNLOADING_APPROVAL_DETAIL,
  SET_APPROVAL_SELECTED_PAGE_SIZE, SET_APPROVAL_TAPPED_ID, SET_APPROVAL_SEARCH_TEXT,
  SET_APPROVAL_SELECTED_ORDER_BY,
  SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  DOWNLOADING_APPROVAL_ATTACHMENT,
  SHOW_APPROVAL_APPROVER_POP_UP,
  SHOW_APPROVAL_COMMENT_POP_UP,
  DOWNLOADING_APPROVAL_PROCESSES,
  DOWNLOADING_APPROVAL_PROVIDERS,
  SET_APPROVAL_SELECTED_STEP_ID,
  SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION,
  RESENDING_EVENT, CANCELING_APPROVAL,
} from '../action';
import { INITIAL_ORDER_BY_APPROVALS } from '../../constant';

const initialState = {
  downloading: false,
  downloadingDetail: false,
  downloadingAttachment: false,
  showApproverPopUp: false,
  showCommentPopUp: false,
  downloadingApprovalProviders: false,
  downloadingApprovalProcesses: false,
  resendingEvent: false,
  cancelingApproval: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_APPROVALS,
  filterString: '',
  selectedStepId: '',
  selectedResendEventOption: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_APPROVALS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_APPROVAL_DETAIL:
      return { ...state, downloadingDetail: action.status };
    case DOWNLOADING_APPROVAL_ATTACHMENT:
      return { ...state, downloadingAttachment: action.status };
    case SHOW_APPROVAL_APPROVER_POP_UP:
      return { ...state, showApproverPopUp: action.status };
    case SHOW_APPROVAL_COMMENT_POP_UP:
      return { ...state, showCommentPopUp: action.status };
    case SET_APPROVAL_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_APPROVAL_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_APPROVAL_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_APPROVAL_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case DOWNLOADING_APPROVAL_PROCESSES:
      return { ...state, downloadingApprovalProcesses: action.status };
    case DOWNLOADING_APPROVAL_PROVIDERS:
      return { ...state, downloadingApprovalProviders: action.status };
    case CANCELING_APPROVAL:
      return { ...state, cancelingApproval: action.status };
    case SET_APPROVAL_SELECTED_STEP_ID:
      return { ...state, selectedStepId: action.id };
    case RESENDING_EVENT:
      return { ...state, resendingEvent: action.status };
    case SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION:
      return { ...state, selectedResendEventOption: action.value };
    default: return state;
  }
};
