import {
  DOWNLOADING_ASSET_LOCATIONS, DOWNLOADING_ASSET_TYPES, DOWNLOADING_ASSETS,
  DOWNLOADING_DELETING_ASSET, SET_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_ASSET_SEARCH_TEXT, SET_ASSET_SELECTED_ORDER_BY, SET_ASSET_SELECTED_PAGE_SIZE,
  SET_ASSET_TAPPED_ID, SET_ASSET_ACTIVE_TAB, SET_ASSET_TYPE_SEARCH_TEXT,
  SET_ASSET_LOCATION_SEARCH_TEXT, CHANGING_ASSET_REMINDER_FLAG, SAVING_ASSETS,
} from '../action';
import { INITIAL_ORDER_BY_ASSETS } from '../../constant';

const initialState = {
  changingAssetReminderFlag: false,
  downloadingAssetTypes: false,
  downloadingAssetLocations: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  selectedPageSize: 20,
  activeTab: '',
  tappedId: '',
  searchBarText: '',
  assetLocationSearchBarText: '',
  assetTypeSearchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_ASSETS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ASSETS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_ASSET:
      return { ...state, downloadingDeleting: action.status };
    case SET_ASSET_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ASSET_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ASSET_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ASSET_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case DOWNLOADING_ASSET_TYPES:
      return { ...state, downloadingAssetTypes: action.status };
    case DOWNLOADING_ASSET_LOCATIONS:
      return { ...state, downloadingAssetLocations: action.status };
    case SET_ASSET_ACTIVE_TAB:
      return { ...state, activeTab: action.text };
    case SET_ASSET_TYPE_SEARCH_TEXT:
      return { ...state, assetTypeSearchBarText: action.text };
    case SET_ASSET_LOCATION_SEARCH_TEXT:
      return { ...state, assetLocationSearchBarText: action.text };
    case CHANGING_ASSET_REMINDER_FLAG:
      return { ...state, changingAssetReminderFlag: action.status };
    case SAVING_ASSETS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
