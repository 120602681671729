import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, Button, Typography, CircularProgress,
} from '@material-ui/core';
import { FunctionalPage, SectionTitle } from '../../../../component';
import {
  configItemUsedOnTypes, dataTypes, FILTER_TYPE_DROPDOWN, FILTER_TYPE_SWITCH, PAGE_MODE_VIEW,
  RXFIELD_CONFIG_ITEM_ALLOW_OVERRIDE, RXFIELD_CONFIG_ITEM_DATA_TYPE,
  RXFIELD_CONFIG_ITEM_DESCRIPTION, RXFIELD_CONFIG_ITEM_ENCRYPTED, RXFIELD_CONFIG_ITEM_ENUM_OPTION,
  RXFIELD_CONFIG_ITEM_CONFIG_GROUP, RXFIELD_CONFIG_ITEM_LABEL, RXFIELD_CONFIG_ITEM_NAME,
  RXFIELD_CONFIG_ITEM_NEED_RESTART, RXFIELD_CONFIG_ITEM_ORDER, RXSTATE_CONFIG_ITEM_PAGE,
  RXFIELD_CONFIG_ITEM_CONFIG_SECTION, RXFIELD_CONFIG_ITEM_USED_ON, RXFIELD_CONFIG_ITEM_VALUE,
  RXSTATE_CONFIG_ITEMS, RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_MULTILINE,
  BOOLEAN_DROPDOWN_DATA_LOWERCASE, ADVANCED_FILTER_MODE, BUTTON_TYPE_OUTLINED, COLOR_PRIMARY,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField, renderReduxFormSwitchField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';
import { rxformValidateConfigItem } from '../../validation';

const styles = {
  uploadButtonContainer: {
    marginTop: 8,
    marginRight: 8,
    height: 38,
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  valueFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  valueField: {
    flex: 1,
  },
};

const ConfigItemPage = ({
  initialValues, configGroups,
  addingEditing, downloading, encrypted, loadingConfigGroups,
  handleSubmit, onAdvancedFilterPressed, onAllowOverrideOptionSelected, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onCancelPressed,
  onChangeConfigGroupText, onChangePage, onChangePageSize, onConfigGroupsOptionSelected,
  onConfirmContextMenuPressed, onCreatePressed, onDataTypeOptionSelected, onDeletePressed,
  onDuplicatePressed, onEditPressed, onEncryptedOptionSelected, onNeedRestartOptionSelected,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onUsedOnOptionSelected, onViewPressed, createPermissionName, pageMode,
  selectedDataType, onMultiLineSelected, isMultiline, isDuplicateAllowed, onUploadPressed,
  onFileSelected, uploadingConfigItemValueFile, createPermissionAllowed, onCreatePermissionPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_CONFIG_ITEMS}
    uiPage={RXSTATE_CONFIG_ITEM_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.configItemScreen.buttonCaptionDuplicate,
        disabled: !isDuplicateAllowed,
        onPress: (tappedId) => { onEditPressed(tappedId); onDuplicatePressed(); },
      },
      {
        caption: LocalizedString.configItemScreen.buttonCaptionCreatePermission,
        disabled: !createPermissionAllowed,
        onPress: (tappedId) => {
          onCreatePermissionPressed(tappedId);
        },
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.configItemScreen.labelConfigGroup,
        field: 'configGroup',
        type: FILTER_TYPE_DROPDOWN,
        data: configGroups,
        loading: loadingConfigGroups,
        onChangeFilterText: (text) => onChangeConfigGroupText(text, ADVANCED_FILTER_MODE),
        onFilterOptionSelected: onConfigGroupsOptionSelected,
      },
      {
        title: LocalizedString.configItemScreen.labelUsedOn, field: 'usedOn', type: FILTER_TYPE_DROPDOWN, data: configItemUsedOnTypes,
      },
      { title: LocalizedString.configItemScreen.labelNeedRestart, field: 'needRestart', type: FILTER_TYPE_SWITCH },
    ]}
    tableColumns={[
      {
        title: LocalizedString.configItemScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.configItemScreen.labelGroup, field: 'configGroup.name', sorting: !downloading },
      { title: LocalizedString.configItemScreen.labelConfigItemName, field: 'name', sorting: !downloading },
      { title: LocalizedString.configItemScreen.labelOrder, field: 'order', sorting: !downloading },
      { title: LocalizedString.configItemScreen.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.configItemScreen.labelValue, field: 'shortValue', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.configItemScreen.buttonCaptionCreateNewConfigItem}
    deleteButtonCaption={LocalizedString.configItemScreen.buttonCaptionDeleteConfigItem}
    title={LocalizedString.configItemScreen.title}
    editButtonCaption={LocalizedString.configItemScreen.buttonCaptionEditConfigItem}
    useFullWidth
    usefullWidthDialog
    createPermissionName={createPermissionName}
    deletePermissionName="CONFIG_DELETE_CONFIG_ITEM"
    editPermissionName="CONFIG_EDIT_CONFIG_ITEM"
  >
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.configItemScreen.placeholderName}
            label={LocalizedString.configItemScreen.placeholderName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item container>
          <Grid item sm md>
            <Field
              name={RXFIELD_CONFIG_ITEM_ORDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.configItemScreen.placeholderOrder}
              label={LocalizedString.configItemScreen.placeholderOrder}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_CONFIG_ITEM_LABEL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.configItemScreen.placeholderLabel}
              label={LocalizedString.configItemScreen.placeholderLabel}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
        <div style={styles.valueFieldContainer}>
          <div style={styles.valueField}>
            <Field
              name={RXFIELD_CONFIG_ITEM_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.configItemScreen.placeholderValue}
              label={LocalizedString.configItemScreen.placeholderValue}
              disabled={
                addingEditing || pageMode === PAGE_MODE_VIEW || uploadingConfigItemValueFile
              }
              secureTextEntry={encrypted}
              multiline={isMultiline}
              rows={3}
              InputProps={{
                readOnly: (selectedDataType || initialValues.dataType) === dataTypes[8],
              }}
              required
            />
          </div>
          {
            (selectedDataType || initialValues.dataType) === dataTypes[8]
              && pageMode !== PAGE_MODE_VIEW ? (
                <div>
                  <Button
                    variant={BUTTON_TYPE_OUTLINED}
                    style={styles.uploadButtonContainer}
                    disabled={
                    addingEditing || pageMode === PAGE_MODE_VIEW || uploadingConfigItemValueFile
                  }
                    onClick={() => onUploadPressed()}
                  >
                    {
                    uploadingConfigItemValueFile ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (<Typography>{GlobalLocalizedString.common.labelUpload}</Typography>)
                  }
                  </Button>
                  <input
                    type="file"
                    id="uploadFile"
                    name="uploadFile"
                    style={{ visibility: 'hidden', display: 'none' }}
                    onChange={(e) => onFileSelected(e.target)}
                  />
                </div>
              ) : null
          }
        </div>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.configItemScreen.placeholderDescription}
            label={LocalizedString.configItemScreen.placeholderDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
        <Grid item container>
          <Grid item sm md>
            <Field
              name={RXFIELD_CONFIG_ITEM_ALLOW_OVERRIDE}
              component={renderReduxFormSwitchField}
              label={LocalizedString.configItemScreen.placeholderAllowOverride}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onOptionSelected={onAllowOverrideOptionSelected}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_CONFIG_ITEM_ENCRYPTED}
              component={renderReduxFormSwitchField}
              label={LocalizedString.configItemScreen.placeholderEncrypted}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onOptionSelected={onEncryptedOptionSelected}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_CONFIG_ITEM_NEED_RESTART}
              component={renderReduxFormSwitchField}
              label={LocalizedString.configItemScreen.placeholderNeedRestart}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onOptionSelected={onNeedRestartOptionSelected}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.configItemScreen.labelDataType} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_DATA_TYPE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.configItemScreen.placeholderDataType}
            label={LocalizedString.configItemScreen.placeholderDataType}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={dataTypes}
            value={initialValues.dataType}
            onOptionSelected={(option) => onDataTypeOptionSelected(option, selectedDataType)}
            required
          />
        </Grid>
        {(selectedDataType || initialValues.dataType) === dataTypes[7] ? (
          <Grid item>
            <Field
              name={RXFIELD_CONFIG_ITEM_ENUM_OPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.configItemScreen.placeholderEnumOption}
              label={LocalizedString.configItemScreen.placeholderEnumOption}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              secondaryHelperText={LocalizedString.configItemScreen.labelEnumInputTips}
              useHelperIcon
              required
            />
          </Grid>
        ) : null}
        {(selectedDataType || initialValues.dataType) === dataTypes[0] ? (
          <Grid item>
            <Field
              name={RXFIELD_CONFIG_ITEM_MULTILINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.configItemScreen.placeholderMultiline}
              label={LocalizedString.configItemScreen.placeholderMultiline}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={BOOLEAN_DROPDOWN_DATA_LOWERCASE}
              value={initialValues.multiLine}
              onOptionSelected={onMultiLineSelected}
              required
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_USED_ON}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.configItemScreen.placeholderUsedOn}
            label={LocalizedString.configItemScreen.placeholderUsedOn}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={configItemUsedOnTypes}
            value={initialValues.usedOn}
            onOptionSelected={onUsedOnOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.configItemScreen.labelConfigGroup} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_CONFIG_GROUP}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.configItemScreen.placeholderConfigGroup}
            label={LocalizedString.configItemScreen.placeholderConfigGroup}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={configGroups}
            value={initialValues.configGroups}
            loading={loadingConfigGroups}
            onChangeText={onChangeConfigGroupText}
            onOptionSelected={onConfigGroupsOptionSelected}
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CONFIG_ITEM_CONFIG_SECTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.configItemScreen.placeholderConfigSection}
            label={LocalizedString.configItemScreen.placeholderConfigSection}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CONFIG_ITEM,
  validate: rxformValidateConfigItem,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConfigItemPage);

ConfigItemPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  configGroups: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  encrypted: PropTypes.bool.isRequired,
  loadingConfigGroups: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  createPermissionAllowed: PropTypes.bool.isRequired,
  isMultiline: PropTypes.bool.isRequired,
  uploadingConfigItemValueFile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAllowOverrideOptionSelected: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeConfigGroupText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfigGroupsOptionSelected: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onCreatePermissionPressed: PropTypes.func.isRequired,
  onDataTypeOptionSelected: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEncryptedOptionSelected: PropTypes.func.isRequired,
  onNeedRestartOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onUsedOnOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  createPermissionName: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedDataType: PropTypes.string,
  onMultiLineSelected: PropTypes.func.isRequired,
  onUploadPressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
};

ConfigItemPage.defaultProps = {
  selectedDataType: '',
};
