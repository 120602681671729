import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { useLocation } from 'react-router-dom';
import {
  DialogContent, makeStyles, Modal,
} from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  ALERT_TYPE_CONFIRMATION, ALERT_TYPE_ERROR, ALERT_TYPE_INFO, ALERT_TYPE_INPUT,
  ALERT_TYPE_SUCCESS, ALERT_TYPE_WARNING, COLOR_DANGEROUS, COLOR_TEXT_LIGHTEST,
  ERR_TOKEN_SESSION_EXPIRED, ROUTE_NAME_LOGIN, COLOR_TEXT_BLACK_SECONDARY,
  RXFIELD_ALERT_BOX_NOTES, RXFORM_ALERT_BOX,
} from '../../constant';
import { StyleShape } from '../../type';
import LocalizedString from '../../localization';
import AccentButton from '../accent-button';
import { rxformValidateAlertBoxNote } from '../../validation';
import OutlinedTextField from '../outlined-text-field';

const confirmationIcon = require('../../asset/confirmation.png');

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
  },
  rowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
  icon: {
    alignSelf: 'center',
  },
  cancelButton: {
    marginRight: 8,
  },
  title: {
    margin: '16px 0px 0px',
    textAlign: 'center',
    color: COLOR_TEXT_BLACK_SECONDARY,
  },
  subtitle: {
    margin: '8px 0px 16px 0px',
    textAlign: 'center',
    color: COLOR_TEXT_LIGHTEST,
  },
}));

const onCancelPress = (onCancelPressed, onHideAlert, setReason, type) => {
  onCancelPressed();
  onHideAlert(type);
  setReason('');
};

const onConfirmPress = (onConfirmPressed, onHideAlert, setReason, message, reason, type) => {
  onConfirmPressed(message, reason);
  onHideAlert(type);
  setReason('');
};

function renderReduxFormOutlinedTextField(props) {
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    helperText,
    defaultValue,
    InputProps,
    inputLabelStyle,
    ...restProps
  } = props;
  const errorProps = touched && error ? error : undefined;
  return (
    <OutlinedTextField
      error={touched && !!error}
      helperText={helperText || errorProps}
      onChangeText={onChange}
      autoComplete="off"
      value={defaultValue || value}
      inputProps={InputProps}
      inputLabelStyle={inputLabelStyle}
      {...restProps}
      {...restInput}
    />
  );
}

function AlertBox({
  forcePopUp, handleSubmit,
  onCancelPressed, onConfirmPressed, onHideAlert,
  message, placeholder, subtitle, type, messageRequired,
}) {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const location = useLocation();

  if ((location && location.pathname !== ROUTE_NAME_LOGIN) || forcePopUp) {
    if (type !== ALERT_TYPE_CONFIRMATION && type !== ALERT_TYPE_INPUT) {
      let defaultTitle = '';
      switch (type) {
        case ALERT_TYPE_INFO: {
          defaultTitle = LocalizedString.common.alertTitleInfo;
          break;
        }
        case ALERT_TYPE_WARNING: {
          defaultTitle = LocalizedString.common.alertTitleWarning;
          break;
        }
        case ALERT_TYPE_ERROR: {
          defaultTitle = LocalizedString.common.alertTitleError;
          break;
        }
        case ALERT_TYPE_SUCCESS: {
          defaultTitle = LocalizedString.common.alertTitleSuccess;
          break;
        }
        default: {
          defaultTitle = '';
          break;
        }
      }

      return (
        <Modal open={!!message} disableBackdropClick disableEscapeKeyDown>
          <DialogContent>
            <SweetAlert
              type={type}
              title={defaultTitle}
              show={!!message}
              onConfirm={() => onHideAlert(type)}
              openAnim={{ name: 'showSweetAlert', duration: 500 }}
              closeOnClickOutside={false}
              customButtons={(
                <AccentButton
                  onClick={() => {
                    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
                      onConfirmPressed(message);
                    }
                    onHideAlert(type);
                  }}
                  variant="text"
                  className={classes.redButton}
                  caption={LocalizedString.common.buttonCaptionOK}
                />
          )}
            >
              {message}
            </SweetAlert>
          </DialogContent>
        </Modal>
      );
    }

    return (
      <Modal open={!!message} disableBackdropClick disableEscapeKeyDown>
        <form
          className={classes.form}
          id="data"
          onSubmit={handleSubmit((e) => {
            onConfirmPress(
              onConfirmPressed,
              onHideAlert,
              setReason,
              message,
              e,
              type,
            );
          })}
        >
          <DialogContent>
            <SweetAlert
              type={ALERT_TYPE_INPUT ? 'custom' : 'success'}
              customIcon={ALERT_TYPE_INPUT && (<img alt="confirmation" className={classes.icon} src={confirmationIcon} width={55} height={55} />)}
              show={!!message}
              title={subtitle ? undefined : LocalizedString.common.alertTitleConfirmation}
              placeholder={placeholder}
              openAnim={{ name: 'showSweetAlert', duration: 500 }}
              closeOnClickOutside={false}
              customButtons={subtitle
                ? (
                  <div className={classes.rowContainer}>
                    <div style={{ flex: 1 }} />
                    <AccentButton
                      onClick={() => onCancelPress(onCancelPressed, onHideAlert, setReason, type)}
                      variant="outlined"
                      caption={LocalizedString.common.buttonCaptionNo}
                      style={classes.cancelButton}
                    />
                    <AccentButton
                      onClick={() => onConfirmPress(onConfirmPressed, onHideAlert,
                        setReason, message, reason, type)}
                      caption={LocalizedString.common.buttonCaptionYes}
                    />
                  </div>
                )
                : (
                  <div className={classes.buttonContainer}>
                    <AccentButton
                      onClick={() => onCancelPress(onCancelPressed, onHideAlert, setReason, type)}
                      variant="text"
                      className={classes.redButton}
                      caption={LocalizedString.common.buttonCaptionNo}
                    />
                    <AccentButton
                      type="submit"
                      caption={LocalizedString.common.buttonCaptionYes}
                    />
                  </div>
                )}
            >
              {message}

              {type === ALERT_TYPE_INPUT && (
              <Field
                name={RXFIELD_ALERT_BOX_NOTES}
                component={renderReduxFormOutlinedTextField}
                placeholder={placeholder}
                label={placeholder}
                multiline
                value={reason}
                required={messageRequired}
                inputLabelStyle={{ width: '11%' }}
              />
              )}
            </SweetAlert>
          </DialogContent>
        </form>
      </Modal>
    );
  }
  return null;
}

export default reduxForm({
  form: RXFORM_ALERT_BOX,
  validate: rxformValidateAlertBoxNote,
  shouldValidate: () => true,
})(AlertBox);

AlertBox.propTypes = {
  forcePopUp: PropTypes.bool.isRequired,
  messageRequired: PropTypes.bool,
  onCancelPressed: PropTypes.func,
  onConfirmPressed: PropTypes.func,
  onHideAlert: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  placeholder: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string.isRequired,
};

AlertBox.defaultProps = {
  messageRequired: false,
  onCancelPressed: () => {},
  onConfirmPressed: () => {},
  message: '',
  placeholder: '',
  subtitle: '',
};


renderReduxFormOutlinedTextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  inputLabelStyle: StyleShape,
  helperText: PropTypes.string,
  defaultValue: PropTypes.string,
  InputProps: PropTypes.object,
};

renderReduxFormOutlinedTextField.defaultProps = {
  helperText: '',
  defaultValue: '',
  InputProps: {},
  inputLabelStyle: {},
};
